import * as Supabase from "@supabase/supabase-js"
import * as React from "react"
import SmsIcon from "../../assets/img/sms.svg"
import WhatsappIcon from "../../assets/img/whatsapp.svg"
import { useAnalytics } from "../analytics"

export const MESSAGE_CHANNELS = [
  {
    key: "sms" as const,
    title: "SMS",
    color: "rgba(106, 170, 226, 1)",
    icon: SmsIcon,
  },
  {
    key: "whatsapp" as const,
    title: "WhatsApp",
    color: "rgba(0, 187, 32, 1)",
    icon: WhatsappIcon,
  },
]

export type MessageChannel = typeof MESSAGE_CHANNELS[number]["key"]

export const DEFAULT_MESSAGE_CHANNEL: MessageChannel = "sms"

const supabase = Supabase.createClient(
  "https://nxjhsovptavgjaocmzej.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im54amhzb3ZwdGF2Z2phb2NtemVqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc2NDMxMjAsImV4cCI6MTk5MzIxOTEyMH0.iDjq2FcUiAZJ7R9jRafaAWx6ykW2qfnaCI0z4kv7XCs",
)

type SupabaseAuthenticationState =
  | {
      status: "uninitialize"
    }
  | {
      status: "unauthenticated"
    }
  | {
      status: "authenticating"
      mobileNumber: string
    }
  | {
      status: "authenticated"
      session: Supabase.Session
    }

export type AuthenticationState =
  | SupabaseAuthenticationState
  | {
      status: "unregistered"
      session: Supabase.Session
    }
  | {
      status: "registered"
      session: Supabase.Session
    }

type AuthenticationAction = {
  signInMobile: (params: {
    mobileNumber: string
    channel: MessageChannel
  }) => Promise<void>
  submitPasscode: (passcode: string) => Promise<void>
  getToken: () => string
  signOut: () => Promise<void>
}
const supabaseAuthenticationContext = React.createContext([
  {} as SupabaseAuthenticationState,
  {} as AuthenticationAction,
] as const)

const authenticationContext = React.createContext([
  {} as AuthenticationState,
  {} as AuthenticationAction,
] as const)

const SupabaseProvider: React.FC = (props) => {
  const [analytics, { trackError }] = useAnalytics()
  const [authenticationState, setAuthenticationState] =
    React.useState<SupabaseAuthenticationState>({
      status: "uninitialize",
    })

  const trackAuthError = ({
    mobile,
    error,
  }: {
    mobile: string | undefined
    error: Supabase.AuthError
  }) =>
    trackError({
      error,
      properties: {
        mobile,
      },
    })

  React.useEffect(() => {
    ;(async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession()
      if (error) {
        trackAuthError({
          mobile: session?.user.phone,
          error,
        })
        throw new Error(
          [`Name: ${error.name}`, `Message: ${error.message}`].join("\n"),
        )
      }
      if (session) {
        setAuthenticationState({
          status: "authenticated",
          session,
        })
      } else {
        setAuthenticationState({
          status: "unauthenticated",
        })
      }
    })()
  }, [])
  React.useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case "SIGNED_IN":
        case "USER_UPDATED":
        case "TOKEN_REFRESHED": {
          if (session) {
            const user = session.user
            analytics?.identify(user.id, {
              mobile: user.phone,
            })
            setAuthenticationState({
              status: "authenticated",
              session,
            })
          }
          break
        }
        case "SIGNED_OUT":
          setAuthenticationState({
            status: "unauthenticated",
          })
          break
      }
    })
    return () => subscription.unsubscribe()
  }, [])

  const signInMobile: AuthenticationAction["signInMobile"] = async ({
    mobileNumber,
    channel,
  }) => {
    const { error } = await supabase.auth.signInWithOtp({
      phone: mobileNumber,
      options: {
        channel,
      },
    })
    if (error) {
      trackAuthError({
        mobile: mobileNumber,
        error,
      })
      throw new Error(
        [`Name: ${error.name}`, `Message: ${error.message}`].join("\n"),
      )
    }
    setAuthenticationState({
      status: "authenticating",
      mobileNumber,
    })
  }
  const submitPasscode: AuthenticationAction["submitPasscode"] = async (
    passcode,
  ) => {
    if (authenticationState.status !== "authenticating") {
      throw new Error(
        "Please key in your mobile number to attempt to sign in first",
      )
    }
    const { error } = await supabase.auth.verifyOtp({
      type: "sms",
      phone: authenticationState.mobileNumber,
      token: passcode,
    })
    if (error) {
      trackAuthError({
        mobile: authenticationState.mobileNumber,
        error,
      })
      throw new Error(
        [`Name: ${error.name}`, `Message: ${error.message}`].join("\n"),
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  const getToken: AuthenticationAction["getToken"] = () => {
    if (authenticationState.status === "authenticated") {
      return authenticationState.session.access_token
    } else {
      throw new Error("Please attempt to sign in or register first")
    }
  }
  const signOut: AuthenticationAction["signOut"] = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) {
      trackAuthError({
        mobile:
          authenticationState.status === "authenticated"
            ? authenticationState.session.user.phone
            : authenticationState.status === "authenticating"
            ? authenticationState.mobileNumber
            : undefined,
        error,
      })
      throw new Error(
        [`Name: ${error.name}`, `Message: ${error.message}`].join("\n"),
      )
    }
  }

  return (
    <supabaseAuthenticationContext.Provider
      value={[
        authenticationState,
        {
          signInMobile,
          submitPasscode,
          getToken,
          signOut,
        },
      ]}
    >
      {props.children}
    </supabaseAuthenticationContext.Provider>
  )
}
const AuthenticationProvider: React.FC = (props) => {
  const [state, action] = React.useContext(supabaseAuthenticationContext)
  const [authenticationState, setAuthenticationState] =
    React.useState<AuthenticationState>({
      status: "uninitialize",
    })

  React.useEffect(() => {
    const changeAuthenticationStatus = (state: SupabaseAuthenticationState) => {
      switch (state.status) {
        case "uninitialize":
        case "unauthenticated":
        case "authenticating":
          setAuthenticationState(state)
          break
        case "authenticated":
          authenticate()
          break
      }
    }
    changeAuthenticationStatus(state)
  }, [state.status])

  const authenticate = () => {
    if (state.status === "authenticated") {
      setAuthenticationState({
        status: "registered",
        session: state.session,
      })
    } else {
      throw new Error("User is not authenticated.")
    }
  }

  return (
    <authenticationContext.Provider value={[authenticationState, action]}>
      {props.children}
    </authenticationContext.Provider>
  )
}
export const Provider: React.FC = (props) => {
  return (
    <SupabaseProvider>
      <AuthenticationProvider>{props.children}</AuthenticationProvider>
    </SupabaseProvider>
  )
}
export const useFirebaseAuthenticationState = () => {
  return React.useContext(supabaseAuthenticationContext)
}
export const useAuthenticationState = () => {
  return React.useContext(authenticationContext)
}
