import { graphqlClient } from "graphql-client/dist/esm"
import * as React from "react"

import { useAnalytics } from "./analytics"
import {
  Provider as AuthenticationProvider,
  useFirebaseAuthenticationState,
} from "./authentication"
import { Upload, getSdk } from "./generated/root.graphql"

export const useGraphqlSdk = () => {
  const [, authenticationStore] = useFirebaseAuthenticationState()
  const [, { trackError }] = useAnalytics()
  return graphqlClient({
    url: import.meta.env.VITE_DIDIAN_CLIENT_API + "/root",
    getToken: () => {
      try {
        const token = authenticationStore.getToken()
        return token
      } catch (error) {
        return undefined as any
      }
    },
    handleError: (error) =>
      trackError({
        error,
        properties: {
          file: "src/stores/index.tsx",
        },
      }),
    getSdk,
  })
}

export const toUpload = (file: File): Upload => {
  // Why do we do this casting?
  // because the conversion is done at backend
  return file as any
}

const providers = [AuthenticationProvider]
const Provider: React.FC = (props) => {
  return providers.reduce(
    (child, Provider) => <Provider>{child}</Provider>,
    <>{props.children}</>,
  )
}
export default Provider
