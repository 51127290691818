import segmentPlugin from "@analytics/segment"
import * as Sentry from "@sentry/react"
import { Analytics } from "analytics"

const writeKey = import.meta.env.VITE_SEGMENT_ANALYTICS_WRITE_KEY
const app = import.meta.env.VITE_BUYER_PORTAL
const analyticsInstance = writeKey
  ? Analytics({
      app,
      plugins: [
        segmentPlugin({
          writeKey,
        }),
      ],
    })
  : undefined
export const useAnalytics = () => {
  return [
    analyticsInstance,
    {
      trackError: ({
        error,
        properties,
      }: {
        error: Error
        properties: Record<string, unknown>
      }) => {
        analyticsInstance?.track("error", {
          name: error.name,
          message: error.message,
          cause: error.cause,
          extra: properties,
        })
        // eslint-disable-next-line import/namespace
        Sentry.captureException(error, {
          extra: properties,
        })
        console.error({
          error,
          extra: properties,
        })
      },
    },
  ] as const
}
