import { createTheme } from "@mui/material/styles"

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      fontWeight: 500,
      lineHeight: "72px",
    },
    h2: {
      fontSize: "48px",
      fontWeight: 500,
      lineHeight: "56px",
    },
    h3: {
      fontSize: "40px",
      fontWeight: 500,
      lineHeight: "48px",
    },
    h4: {
      fontSize: "32px",
      fontWeight: 500,
      lineHeight: "40px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "32px",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14px",
    },
  },
  palette: {
    primary: {
      dark: "rgba(61, 186, 177, 1)",
      main: "rgba(81, 200, 192, 1)",
      light: "rgba(121, 211, 206, 1)",
    },
    text: {
      primary: "rgba(47, 78, 107, 1)",
      secondary: "rgba(66, 66, 66, 1)",
      disabled: "rgba(234, 58, 90, 1)",
    },
    yellow: {
      600: "rgba(255, 219, 92, 1)",
      500: "rgba(255, 225, 120, 1)",
      400: "rgba(255, 231, 147, 1)",
      300: "rgba(255, 237, 175, 1)",
      200: "rgba(255, 243, 202, 1)",
      100: "rgba(255, 249, 230, 1)",
    },
    teal: {
      600: "rgba(61, 186, 177, 1)",
      500: "rgba(81, 200, 192, 1)",
      400: "rgba(121, 211, 206, 1)",
      300: "rgba(160, 224, 220, 1)",
      200: "rgba(198, 236, 234, 1)",
      100: "rgba(233, 252, 251, 1)",
    },
    blue: {
      600: "rgba(47, 78, 107, 1)",
      500: "rgba(62, 103, 142, 1)",
      400: "rgba(78, 129, 177, 1)",
      300: "rgba(113, 154, 193, 1)",
      200: "rgba(149, 179, 208, 1)",
      100: "rgba(188, 210, 232, 1)",
    },
    lightblue: {
      600: "rgba(75, 140, 198, 1)",
      500: "rgba(106, 170, 226, 1)",
      400: "rgba(100, 175, 234, 1)",
      300: "rgba(149, 194, 234, 1)",
      200: "rgba(191, 218, 242, 1)",
      100: "rgba(234, 243, 251, 1)",
    },
    orange: {
      600: "rgba(251, 160, 85, 1)",
      500: "rgba(251, 171, 106, 1)",
      400: "rgba(252, 185, 131, 1)",
      300: "rgba(255, 199, 156, 1)",
      200: "rgba(255, 213, 180, 1)",
      100: "rgba(255, 240, 228, 1)",
    },
    red: {
      600: "rgba(176, 0, 54, 1)",
      500: "rgba(219, 4, 70, 1)",
      400: "rgba(234, 58, 90, 1)",
      300: "rgba(249, 111, 109, 1)",
      200: "rgba(255, 156, 158, 1)",
      100: "rgba(255, 234, 240, 1)",
    },
    grey: {
      600: "rgba(66, 66, 66, 1)",
      500: "rgba(102, 102, 102, 1)",
      400: "rgba(127, 140, 152, 1)",
      300: "rgba(187, 194, 201, 1)",
      200: "rgba(218, 220, 223, 1)",
      100: "rgba(244, 245, 246, 1)",
    },
    white: {
      600: "rgba(255, 255, 255, 1)",
    },
  },
  shadows: [
    "none",
    ...Array(24)
      .fill(undefined)
      .map(
        (_, index) =>
          `0px 0px ${index + 1}px rgba(0, 0, 0, ${0.04 * (index + 1)})`,
      ),
  ] as any,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          switch (ownerState.color) {
            case "primary":
              return {
                color: theme.palette.blue[600],
              }
            case "success":
              return {
                color: theme.palette.teal[600],
              }
            case "warning":
              return {
                color: theme.palette.orange[600],
              }
            case "danger":
              return {
                color: theme.palette.red[600],
              }
            case "neutral":
            case "info":
              return {
                color: theme.palette.yellow[600],
              }
            case "yellow-600":
            case "yellow-500":
            case "yellow-400":
            case "yellow-300":
            case "yellow-200":
            case "yellow-100":
            case "teal-600":
            case "teal-500":
            case "teal-400":
            case "teal-300":
            case "teal-200":
            case "teal-100":
            case "blue-600":
            case "blue-500":
            case "blue-400":
            case "blue-300":
            case "blue-200":
            case "blue-100":
            case "lightblue-600":
            case "lightblue-500":
            case "lightblue-400":
            case "lightblue-300":
            case "lightblue-200":
            case "lightblue-100":
            case "orange-600":
            case "orange-500":
            case "orange-400":
            case "orange-300":
            case "orange-200":
            case "orange-100":
            case "red-600":
            case "red-500":
            case "red-400":
            case "red-300":
            case "red-200":
            case "red-100":
            case "grey-600":
            case "grey-500":
            case "grey-400":
            case "grey-300":
            case "grey-200":
            case "grey-100": {
              const colors = ownerState.color.split("-")
              return {
                color:
                  theme.palette[colors[0] as "yellow"][
                    parseInt(colors[1]) as 600
                  ],
              }
            }
          }
          return {}
        },
      },
    },
  },
})
