import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import * as Sentry from "@sentry/react"
import * as React from "react"
import * as ReactDOM from "react-dom"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import * as ReactRouter from "react-router-dom"

import { route, stringParser } from "typesafe-routes"
import StoreProvider from "./stores"
import { useAnalytics } from "./stores/analytics"
import theme from "./theme"

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    debug: import.meta.env.VITE_ENV === "development",
    environment: import.meta.env.VITE_ENV,
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
    integrations: [
      // eslint-disable-next-line import/namespace
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/client\.didian\.co/,
          /^https:\/\/staging\.didian\.co/,
        ],
      }),
      // new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  })
}

export const authenticationLoginRoute = route(
  "login&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationLoginPage = React.lazy(
  () => import("./components/authentication/1a-login"),
)

export const authenticationIdentifyRoute = route(
  "identify&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationIdentifyPage = React.lazy(
  () => import("./components/authentication/1b-identify"),
)

export const authenticationOneTimePasscodeRoute = route(
  "one-time-passcode&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationOneTimePasscodePage = React.lazy(
  () => import("./components/authentication/2-one-time-passcode"),
)

export const authenticationVerifyBookingRoute = route(
  "verify-booking&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationVerifyBookingPage = React.lazy(
  () => import("./components/authentication/3-verify-booking"),
)

export const authenticationSuccessRoute = route(
  "success&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationSuccessPage = React.lazy(
  () => import("./components/authentication/4-success"),
)

export const authenticationLoanApplicationPreferenceRoute = route(
  "loan-application-preference&:buyerId&:booking",
  {
    buyerId: stringParser,
    booking: stringParser,
  },
  {},
)

const AuthenticationLoanApplicationPreferencePage = React.lazy(
  () => import("./components/authentication/5-loan-application-preference"),
)

export const authenticationBookingProgressRoute = route(
  "booking-progress&:buyerId&:booking",
  {
    buyerId: stringParser,
    booking: stringParser,
  },
  {},
)

const AuthenticationBookingProgressPage = React.lazy(
  () => import("./components/authentication/6-booking-progress"),
)

export const authenticationSelectBankRoute = route(
  "select-bank&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationSelectBankPage = React.lazy(
  () => import("./components/authentication/7-select-bank"),
)

export const authenticationUploadDocumentRoute = route(
  "upload-document&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationUploadDocumentPage = React.lazy(
  () => import("./components/authentication/8-upload-document"),
)

export const authenticationBankerResultRoute = route(
  "banker-result&:booking",
  {
    booking: stringParser,
  },
  {},
)

const AuthenticationBankerResultPage = React.lazy(
  () => import("./components/authentication/9-banker-result"),
)

export const authenticationRoute = route(
  "/authentication",
  {
    booking: stringParser,
  },
  {
    authenticationLoginRoute,
    authenticationIdentifyRoute,
    authenticationOneTimePasscodeRoute,
    authenticationVerifyBookingRoute,
    authenticationSuccessRoute,
    authenticationLoanApplicationPreferenceRoute,
    authenticationBookingProgressRoute,
    authenticationUploadDocumentRoute,
    authenticationSelectBankRoute,
    authenticationBankerResultRoute,
  },
)

const AuthenticationPage = React.lazy(
  () => import("./components/authentication"),
)

export const fourOFourRoute = route("*", {}, {})

const FourOFourPage = React.lazy(() => import("./components/404"))

const AdjustHeight: React.FC = () => {
  React.useEffect(() => {
    const listenResize = () => {
      window.requestAnimationFrame(
        () => (document.body.style.height = `${window.innerHeight}px`),
      )
    }
    listenResize()
    const observer = new ResizeObserver(listenResize)
    observer.observe(document.documentElement)
    return () => {
      observer.disconnect()
    }
  }, [])
  return null
}
const DidianRoutes: React.FC = () => {
  const [analytics] = useAnalytics()
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    analytics?.page()
  }, [location])

  return (
    <Routes>
      <Route index element={<Navigate to={authenticationRoute.template} />} />
      <Route
        path={authenticationRoute.template}
        element={<AuthenticationPage />}
      >
        <Route
          index
          element={<Navigate to={authenticationLoginRoute.template} />}
        />
        <Route
          path={authenticationLoginRoute.template}
          element={<AuthenticationLoginPage />}
        />
        <Route
          path={authenticationIdentifyRoute.template}
          element={<AuthenticationIdentifyPage />}
        />
        <Route
          path={authenticationOneTimePasscodeRoute.template}
          element={<AuthenticationOneTimePasscodePage />}
        />
        <Route
          path={authenticationVerifyBookingRoute.template}
          element={<AuthenticationVerifyBookingPage />}
        />
        <Route
          path={authenticationSuccessRoute.template}
          element={<AuthenticationSuccessPage />}
        />
        <Route
          path={authenticationLoanApplicationPreferenceRoute.template}
          element={<AuthenticationLoanApplicationPreferencePage />}
        />
        <Route
          path={authenticationBookingProgressRoute.template}
          element={<AuthenticationBookingProgressPage />}
        />
        <Route
          path={authenticationUploadDocumentRoute.template}
          element={<AuthenticationUploadDocumentPage />}
        />
        <Route
          path={authenticationSelectBankRoute.template}
          element={<AuthenticationSelectBankPage />}
        />
        <Route
          path={authenticationBankerResultRoute.template}
          element={<AuthenticationBankerResultPage />}
        />
      </Route>
      <Route path={fourOFourRoute.template} element={<FourOFourPage />} />
    </Routes>
  )
}
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AdjustHeight />
    <CssBaseline />
    <Router>
      <StoreProvider>
        <React.Suspense
          fallback={<div style={{ width: "100%", height: "100%" }} />}
        >
          <DidianRoutes />
        </React.Suspense>
      </StoreProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById("root"),
)
